
import Vue from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default Vue.extend({
  layout: "clean",
  meta: {
    pageId: AnalyticsEventPageId.progressPhotosUnsubscribe,
    public: true,
  },
  async asyncData({ redirect, route }) {
    const accountStore = useAccountStore()
    const isUnsubscribeToken = route.query.token ? true : false
    if (!isUnsubscribeToken && !accountStore.token) {
      redirect("/v2/users/signin")
    }
  },
  data() {
    return {
      unsubscribed: false,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    routeCameraExid() {
      const camelizedKeys = camelizeKeys(this.$route.params) as {
        cameraExid: string
      }

      return camelizedKeys?.cameraExid
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
  methods: {
    unsubscribe() {
      let params = {
        email: this.$route.query.email,
        token: this.$route.query.token,
      }
      this.$analytics.saveEvent(AnalyticsEvent.progressPhotoUnsubscribeUser)
      EvercamApi.progressPhotos
        .unsubscribe(this.$route.params.id, params)
        .then(() => {
          this.$notifications.success(
            "Progress Photo settings updated successfully."
          )
          this.unsubscribed = true
          this.$router.push("/v2/automations/progress-photos")
        })
    },
  },
})
